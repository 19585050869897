.modesPage {
  background-color: hsl(220, 9%, 7%);
  height: 100vh;
}

.titleContainer {
  display: flex;
  justify-content: center;

  margin: 10rem 0 10rem 0;

  font-size: 2rem;
  font-family: "SpotifyLarge";
  text-align: center;

  color: white;
  animation: pulse 2s;
}

.modesContainer {
  display: flex;
  justify-content: space-around;
  margin: 0 min(5%, 200px) 0 min(5%, 200px);

  animation: pulse;
  animation-duration: 2.7s;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
