/* @import url(//db.onlinewebfonts.com/c/21a1184148d11d67c19f5076b909d63b?family=Circular+Spotify+Tx+T+Book); */

.modeCardContainer {
  display: block;
  width: min(20rem, 40%);
  white-space: pre-wrap;
}

.modeCard {
  background-color: transparent;
  width: 100%;
  height: 200px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.modeCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.modeCard:hover .modeCardInner {
  transform: rotateY(180deg);
}

.modeCardFront,
.modeCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  font-family: "SpotifyMedium";
}

.modeCardBack {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  background-color: #19720b;
  color: white;
  transform: rotateY(180deg);
}

.modeCardBackText {
  padding: 0 20px;
}

.modeButton {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 30px;

  text-align: center;
  font-size: 90%;
  font-family: "SpotifyMedium";
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;

  background-color: #19720b;
  border-color: #19720b;
  color: white;
  border-radius: 5px;
}

.modeButton:hover {
  opacity: 0.8;
  background-color: #ff0000;
  border-color: #ff0000;
}

.modeImage {
  display: block;
  width: 100%;
  height: 200px;
  background-color: #19720b;
  border-color: #19720b;
  line-height: 0.9;
  border-radius: 5px;
  z-index: 1000;
}

.modeImage:hover {
  opacity: 0.6;
}

.disabled {
  background-color: #696969 !important;
  border-color: #696969 !important;
  cursor: default;
  opacity: 0.8;
}

.disabled:hover {
  background-color: #696969 !important;
  border-color: #696969 !important;
  cursor: default;
  opacity: 0.8;
}
