.fullScreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: hsl(220, 9%, 7%);
  overflow: hidden;
}

.playerContainer {
  display: flex;
  margin: 2rem 0 0 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}

.instructions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: center;
  margin: 0.5rem 0 1rem 0;
  flex-grow: 1;
}

.instruction {
  display: flex;
  margin: 0.2rem 0 0.2rem 0;
  font-size: 1rem;
  font-family: "SpotifyMedium";
}

.instructionText {
  align-self: flex-end;
  justify-content: flex-end;
  margin-left: 10px;

  color: white;
}

.spotifyIcon {
  width: 2rem;
  height: 1.5rem;
  align-self: center;
}

.spotifyLogo {
  width: 20px;
  height: 20px;
}

#player {
}

.invisible {
  visibility: hidden;
}

.trackName {
  font-family: "SpotifyLarge";
  font-size: 4rem;
  text-align: center;

  color: white;
}

.artist {
  font-family: "SpotifyLarge";
  font-size: 2rem;
  text-align: center;

  color: white;
}
