.navigationContainer {
  display: flex;
}

.navigationMessage {
  flex-grow: 1;
  color: white;
  font-family: "SpotifyMedium";
  text-align: center;
  align-self: center;
  padding: auto auto;
}
