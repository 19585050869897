* {
  text-decoration: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --spotify-hsl: 141, 76%, 48%;
  --youtube-hsl: 0, 100%, 50%;
  --dark-background-hsl: 220, 9%, 7%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SpotifyLarge";
  src: local("SpotifyLarge"),
    url("./fonts/CircularSpotifyTxT-Black.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SpotifyMedium";
  src: local("SpotifyMedium"),
    url("./fonts/CircularSpotifyTxT-Book.ttf") format("truetype");
  font-weight: normal;
}
