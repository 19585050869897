/* @import url('https://fonts.googleapis.<link href="https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap" rel="stylesheet">/css?family=Anton');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");
@import url("//db.onlinewebfonts.com/c/01173b246d9d9ea808ea75a26b3b61bb?family=Circular+Spotify+Tx+T+Black"); */

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 10rem, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 10rem, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.landingPage {
  background-color: hsl(220, 9%, 7%);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  height: 100vh;

  overflow: hidden;
}

.loginContainer {
  display: grid;
  width: min(500px, 70%);

  /* border: 0.1rem solid hsl(230, 8%, 16%); */
  border-radius: 1rem;
  /* background-color: hsl(230, 8%, 16%); */
  opacity: 100%;
  margin-bottom: 15rem;
  animation: fadeInUp 3s;
}

.titleContainer {
  font-size: 2rem;
  text-align: center;
  font-family: "SpotifyLarge";

  color: white;

  margin: 5rem 0 3rem 0;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: min(5rem, 20px);

  margin: 0rem 0 3rem 0;
}

.logo {
  width: 6rem;
  height: 5rem;
}

.loginButton {
  font-family: "SpotifyLarge";
  font-size: 1.1rem;

  height: 80px;
  width: 70%;
  margin: 0 0 5rem 0;

  border: 0.1rem solid hsl(var(--spotify-hsl));
  background-color: hsl(220, 9%, 7%);
  border-radius: 0.5rem;
  color: white;
}

.loginButton:hover {
  border: 0.1rem solid hsl(var(--youtube-hsl));

  cursor: pointer;
}

.linkButton {
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 100%;
}

.linkButton:hover {
  text-decoration: none;
}
